













































































import Ueditor from "@/components/Ueditor/index.vue";
import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  FundManageFeeFeeRecordDetailDto,
  FundManageFeeRecordChangeRequestInput,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({
  components: {
    AbSelect,
    Ueditor,
  },
})
export default class CreateFundManageFeeRecord extends Vue {
  @Ref() readonly dataForm!: ElForm;
  form: FundManageFeeFeeRecordDetailDto = {
    id: 0,
  };
  formNew: FundManageFeeRecordChangeRequestInput = {
    amount: 0,
  };

  beforeAmount = 0;

  created() {
    if (this.$route.params.id) {
      api.fundManageFeeRecord
        .get({ id: Number(this.$route.params.id) })
        .then((res) => {
          this.form = { ...res };
          if (this.form && this.form.moneyAmount) {
            this.formNew.amount = this.form.moneyAmount;
            this.beforeAmount = this.form.moneyAmount;
          }
        });
    }
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.form!.id) {
          this.formNew.id = this.form.id;
          await api.fundManageFeeRecord.change({
            body: this.formNew,
          });
        }
        this.$router.back();
        this.$message.success("更新成功");
      } else {
        this.$message.error("请检查表单！");
      }
    });
  }

  cancel() {
    this.$router.back();
  }

  roleRule = {
    sumamry: [
      {
        required: true,
        message: "变更原因说明必填",
        trigger: "blur",
      },
    ],
    amount: [
      {
        required: true,
        message: "目标金额必填",
        trigger: "blur",
      },
    ],
  };
}
